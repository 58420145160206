<template>
  <KTCodePreview title="Sức bán & Tồn kho" class="overflow-hidden">
    <template v-slot:preview>
      <b-container
        fluid
        ref="barChartContainer"
        :style="{ height: `${getLoadingStatus} ? 50vh : 'auto'` }"
      >
        <b-row class="align-items-end">
          <b-col md="4" sm="12">
            <b-form-group label="Nhập cửa hàng">
              <MultiselectOptionV2
                id="store-options"
                :model.sync="selectedStores"
                :options="filteredStoreOptions"
                :suggestion-name="'name'"
                :trackBy="'id'"
                :placeholder="'cửa hàng'"
                @select="
                  selectResourceHandler({
                    option: $event,
                    options: filteredStoreOptions,
                    trackBy: 'id',
                    filteredOptions: filteredStoreOptions,
                    checked: true,
                  })
                "
                @remove="
                  selectResourceHandler({
                    option: $event,
                    options: filteredStoreOptions,
                    trackBy: 'id',
                    filteredOptions: filteredStoreOptions,
                    checked: false,
                  })
                "
                :select-all-option="true"
                @searchChange="onInputChangeStore($event)"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" sm="12">
            <AboriginalTreeselect
              name="danh mục"
              :model="selectedCategories"
              :options="categoryOptions"
              :loadOptions="loadCategoryOptions"
              :multiple="true"
              @update:model="(value) => (selectedCategories = value)"
            />
          </b-col>

          <b-col md="3" sm="12">
            <b-form-group label="Nhập ngày xem report">
              <date-picker
                size="sm"
                class="form-control form-control-sm"
                :config="dpConfig"
                v-model="endDate"
                id="report-end-date"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="1" sm="12">
            <b-form-group>
              <b-button
                size="sm"
                variant="primary"
                @click="fetchData"
                :disabled="!!getLoadingStatus"
                >Lọc
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-8">
          <b-col md="5" sm="12"></b-col>
          <b-col md="6" sm="12"></b-col>
          <b-col md="1"></b-col>
        </b-row>
        <b-row>
          <b-col md="6" sm="12"></b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row class="shadow-sm p-3 mb-5 bg-white rounded">
          <b-col sm="12" class="text-left py-2">
            <div class="d-inline-block">
              <h4 class="p-0 border-bottom border-dark">
                Số tồn & Số bán hằng ngày của tháng {{ payloadMonth }}
              </h4>
            </div>
          </b-col>
          <b-col md="12" sm="12" class="px-0 mx-0">
            <D3ObjectChartContainer
              id="sales-capacity-inventory-general-information"
              :data="{ value: 1 }"
              :is-loading="isLoading.generalInformation"
              :chart-height="chartHeight"
            >
            </D3ObjectChartContainer>
          </b-col>
        </b-row>
        <b-row class="shadow-sm p-3 mb-5 bg-white rounded">
          <b-col sm="12" class="text-left py-2">
            <div class="d-inline-block">
              <h4 class="p-0 border-bottom border-dark">
                Sức bán & Tồn kho: theo Số lượng
              </h4>
            </div>
          </b-col>
          <b-col md="12" sm="12">
            <D3ArrayChartContainer
              id="sales-capacity-inventory-product-quantity-sales-capacity-funnel-chart"
              :is-loading="isLoading.predictedDates"
              :chart-height="chartHeight"
              :has-legends="true"
              title="Sức bán trung bình (1 ngày) - 7 ngày qua"
              :data="predictedDates"
            ></D3ArrayChartContainer>
          </b-col>
          <b-col md="12" sm="12">
            <D3ArrayChartContainer
              id="sales-capacity-inventory-product-quantity-predicted-dates-funnel-chart"
              :is-loading="isLoading.salesCapacity"
              :has-legends="true"
              :chart-height="chartHeight"
              title="Ngày clear tồn dự kiến - Sức bán 7 ngày"
              :data="salesCapacity"
            ></D3ArrayChartContainer>
          </b-col>
        </b-row>
        <b-row class="shadow-sm p-3 mb-5 bg-white rounded">
          <b-col sm="12" class="text-left py-2">
            <div class="d-inline-block">
              <h4 class="p-0 border-bottom border-dark">
                Sức bán & Tồn kho: theo Doanh thu
              </h4>
            </div>
          </b-col>
          <b-col sm="12">
            <D3ArrayChartContainer
              id="sales-capacity-inventory-product-quantity-revenue-net-sales-per-week-funnel-chart"
              :is-loading="isLoading.netSalesPerWeekRevenue"
              :chart-height="chartHeight"
              :has-legends="true"
              title="Sức bán trung bình (1 ngày) - Doanh thu 7 ngày qua"
              :data="netSalesPerWeekRevenue"
            ></D3ArrayChartContainer>
          </b-col>
          <b-col sm="12">
            <D3ArrayChartContainer
              id="sales-capacity-inventory-product-quantity-revenue-clear-stock-revenue-funnel-chart"
              :is-loading="isLoading.datesToClearStockRevenue"
              :chart-height="chartHeight"
              :has-legends="true"
              title="Ngày Clear tồn - Doanh thu 7 ngày qua"
              :data="datesToClearStockRevenue"
            ></D3ArrayChartContainer>
          </b-col>
        </b-row>
        <b-row class="shadow-sm p-3 mb-5 bg-white rounded">
          <b-col sm="12" class="text-left py-2">
            <div class="d-inline-block">
              <h4 class="p-0 border-bottom border-dark">
                Sức bán & Tồn kho: theo Giá vốn
              </h4>
            </div>
          </b-col>
          <b-col sm="12">
            <D3ArrayChartContainer
              id="sales-capacity-inventory-mean-sales-original-price-funnel-chart"
              :is-loading="isLoading.meanSalesCapacityOriginalPrice7Days"
              :chart-height="chartHeight"
              :has-legends="true"
              title="Ngày Clear tồn - Giá vốn 7 ngày qua"
              :data="meanSalesCapacityOriginalPrice7Days"
            ></D3ArrayChartContainer>
          </b-col>
          <b-col sm="12">
            <D3ArrayChartContainer
              id="sales-capacity-inventory-dates-clear-stock-original-price-funnel-chart"
              :is-loading="isLoading.datesToClearStockOriginalPrice"
              :chart-height="chartHeight"
              :has-legends="true"
              title="Ngày Clear tồn - Giá vốn 7 ngày qua"
              :data="datesToClearStockOriginalPrice"
            ></D3ArrayChartContainer>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </KTCodePreview>
</template>
<script>
import { DP_CONFIG, getCurrentDateByFormat, getMonth } from '@/utils/date';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { formatPrice, removeAccents } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { DEBOUNCE, SUCCESS_RESPONSE_STATUS } from '@/utils/constants';
import { swalMixin } from '@/view/mixins';
import debounce from 'debounce';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import D3ArrayChartContainer from '@/view/components/bi/D3ArrayChartContainer.vue';
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect';
import AboriginalTreeselect from '@/view/base/treeselect/AboriginalTreeselect.vue';
import vueMultiselectMixin from '@/view/mixins/vue-multiselect.mixins';
import MultiselectOptionV2 from '@/view/base/multiselect/MultiselectOptionV2.vue';
import { d3FunnelChartMixins } from '@/view/mixins/d3/d3FunnelChartMixins';
import D3ObjectChartContainer from '@/view/components/bi/D3ObjectChartContainer.vue';
import EachAfterTranslation from '@/utils/models/D3/EachAfter';
import { d3HierarchyMixins } from '@/view/mixins/d3/d3HierarchyMixins';
import { assign, cloneDeep, find, map } from 'lodash';

export default {
  components: {
    D3ObjectChartContainer,
    AboriginalTreeselect,
    MultiselectOptionV2,
    KTCodePreview,
    D3ArrayChartContainer,
  },
  mixins: [
    swalMixin,
    vueMultiselectMixin,
    d3FunnelChartMixins,
    d3HierarchyMixins,
  ],
  data() {
    return {
      dpConfig: DP_CONFIG.date,
      storeName: '',
      storeAddress: '',
      totalProductSold: 0,
      revenuePerStore: 0,
      chartHeight: 680,

      // isLoading: false,
      apiParams: {
        storeKeyword: '',
      },
      data: {},

      salesCapacity: [],
      predictedDates: [],
      datesToClearStockRevenue: [],
      netSalesPerWeekRevenue: [],
      meanSalesCapacityOriginalPrice7Days: [],
      datesToClearStockOriginalPrice: [],
      generalInformation: {},

      isLoading: {
        salesCapacity: false,
        predictedDates: false,
        datesToClearStockRevenue: false,
        netSalesPerWeekRevenue: false,
        meanSalesCapacityOriginalPrice7Days: false,
        datesToClearStockOriginalPrice: false,
        generalInformation: false,
      },
      storeKeyword: '',
      storeIds: [],
      startDate: '',
      endDate: '',

      selectedCategories: [],
      categoryOptions: [],
      selectedEmployees: [],
      filteredEmployeeOptions: [],
      selectedStores: [],
      filteredStoreOptions: [],
    };
  },
  methods: {
    fetchStoresByJobTitle() {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/bi-report/stores/job-title?storeKeyword=${this.storeKeyword}`,
        )
          .then(({ data }) => {
            if (data.status === SUCCESS_RESPONSE_STATUS) {
              return data;
            }
          })
          .then((data) => {
            this.$set(this, 'storeIds', data.data);

            this.filteredStoreOptions = data.data.map((store) => {
              this.$set(store, 'checked', true);
              return store;
            });
            this.selectedStores = data.data.map((store) => {
              this.$set(store, 'checked', true);
              return store;
            });
            resolve(); // Resolve the promise once data is fetched and updated
          })
          .catch((error) => {
            this.popupSwalError({
              title: 'Lỗi!',
              text:
                error.response.data.message ??
                'Không lấy được danh sách các cửa hàng',
            });
            reject(error); // Reject the promise if there's an error
          });
      });
    },
    debounceStoreInput: debounce(function() {
      this.fetchStoresByJobTitle();
    }, DEBOUNCE.SEARCH_DELAY),
    fetchData() {
      if (this.noMandatoryFields) {
        this.popupSwalWarning({
          title: 'Cảnh báo!',
          text: 'Bạn chưa chọn ngày để xem report',
        });
        return;
      }

      for (const key in this.isLoading) {
        this.isLoading[key] = true;
      }

      const payload = {
        storeIds: this.selectedStores
          .map((store) => {
            if (store.checked) return Number(store.id);
          })
          .sort(),
        endDate: this.endDate,
        categoryIds: [...this.selectedCategories]
          .sort()
          .map((selectedCategory) => Number(selectedCategory)),
      };

      ApiService.post(
        cmdUrl.BIReportResource.salesCapacityInventory.byProductQuantity,
        payload,
      )
        .then(({ data }) => {
          if (!data.data || data.data.length < 1) {
            this.popupSwalWarning({
              title: 'Cảnh báo!',
              text:
                'Không có dữ liệu cho báo cáo Sức bán & Tồn kho: theo Số lượng',
            });
            return;
          }
          return data.data;
        })
        .then((data) => {
          data.salesCapacity.forEach((dto) => {
            dto.value = dto.meanSalesCapacityForSevenDays;
            dto.stage = dto.categoryName;
          });
          data.predictedDates.forEach((dto) => {
            dto.value = dto.predictedDatesToClearStock;
            dto.stage = dto.categoryName;
          });
          this.salesCapacity = data.salesCapacity;
          this.predictedDates = data.predictedDates;
          this.$nextTick(() => {
            this.initializeD3FunnelChart({
              data: this.salesCapacity,
              domElementId:
                '#sales-capacity-inventory-product-quantity-sales-capacity-funnel-chart',
              unit: 'Sức bán trung bình 1 ngày (7 ngày qua)',
            });
            this.initializeD3FunnelChart({
              data: this.predictedDates,
              domElementId:
                '#sales-capacity-inventory-product-quantity-predicted-dates-funnel-chart',
              unit: 'Số ngày clear tồn dự kiến (theo sức bán 7 ngày qua)',
            });
          });
        })
        .catch((error) => {
          if (error) {
            this.popupSwalError({
              title: 'Lỗi!',
              text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
            });
          }
        })
        .finally(() => {
          this.isLoading.salesCapacity = false;
          this.isLoading.predictedDates = false;
        });
      ApiService.post(
        cmdUrl.BIReportResource.salesCapacityInventory.byRevenue,
        payload,
      )
        .then(({ data }) => {
          if (!data.data || data.data.length < 1) {
            this.popupSwalWarning({
              title: 'Cảnh báo!',
              text:
                'Không có dữ liệu cho báo cáo Sức bán & Tồn kho: theo Doanh thu',
            });
            return;
          }
          return data.data;
        })
        .then((data) => {
          data.datesToClearStockRevenue.forEach((dto) => {
            dto.value = dto.predictedDatesToClearStockByRevenue;
            dto.stage = dto.categoryName;
          });
          data.netSalesPerWeekRevenue.forEach((dto) => {
            dto.value = dto.categoryNetSalesPerWeek;
            dto.stage = dto.categoryName;
          });
          this.datesToClearStockRevenue = data.datesToClearStockRevenue;
          this.netSalesPerWeekRevenue = data.netSalesPerWeekRevenue;
          this.$nextTick(() => {
            this.initializeD3FunnelChart({
              data: this.netSalesPerWeekRevenue,
              domElementId:
                '#sales-capacity-inventory-product-quantity-revenue-net-sales-per-week-funnel-chart',
              unit: 'Sức bán trung bình 1 ngày (7 ngày qua) - Doanh thu',
            });
            this.initializeD3FunnelChart({
              data: this.datesToClearStockRevenue,
              domElementId:
                '#sales-capacity-inventory-product-quantity-revenue-clear-stock-revenue-funnel-chart',
              unit: 'Ngày clear tồn dự kiến - Doanh thu 7 ngày qua',
            });
          });
        })
        .catch((error) => {
          if (error) {
            this.popupSwalError({
              title: 'Lỗi!',
              text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
            });
          }
        })
        .finally(() => {
          this.isLoading.datesToClearStockRevenue = false;
          this.isLoading.netSalesPerWeekRevenue = false;
        });

      ApiService.post(
        cmdUrl.BIReportResource.salesCapacityInventory.byOriginalPrice,
        payload,
      )
        .then(({ data }) => {
          if (!data.data || data.data.length < 1) {
            this.popupSwalWarning({
              title: 'Cảnh báo!',
              text: 'Không có dữ liệu cho báo cáo Sức bán & Tồn kho: theo Giá ',
            });
            return;
          }
          return data.data;
        })
        .then((data) => {
          data.meanSalesCapacityOriginalPrice7Days.forEach((dto) => {
            dto.value = dto.meanSalesCapacityOriginalPrice7Days;
            dto.stage = dto.categoryName;
          });
          data.datesToClearStockOriginalPrice.forEach((dto) => {
            dto.value = dto.datesToClearStockOriginalPrice;
            dto.stage = dto.categoryName;
          });
          this.meanSalesCapacityOriginalPrice7Days =
            data.meanSalesCapacityOriginalPrice7Days;
          this.datesToClearStockOriginalPrice =
            data.datesToClearStockOriginalPrice;
          this.$nextTick(() => {
            this.initializeD3FunnelChart({
              data: this.meanSalesCapacityOriginalPrice7Days,
              domElementId:
                '#sales-capacity-inventory-mean-sales-original-price-funnel-chart',
              unit: 'Sức bán trung bình 1 ngày (7 ngày qua) - Giá vốn',
            });
            this.initializeD3FunnelChart({
              data: this.datesToClearStockOriginalPrice,
              domElementId:
                '#sales-capacity-inventory-dates-clear-stock-original-price-funnel-chart',
              unit: 'Ngày Clear tồn dự kiến - Giá vốn',
            });
          });
        })
        .catch((error) => {
          if (error) {
            this.popupSwalError({
              title: 'Lỗi!',
              text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
            });
          }
        })
        .finally(() => {
          this.isLoading.meanSalesCapacityOriginalPrice7Days = false;
          this.isLoading.datesToClearStockOriginalPrice = false;
        });

      ApiService.post(
        cmdUrl.BIReportResource.salesCapacityInventory.generalInformation,
        payload,
      )
        .then(({ data }) => {
          if (!data.data || data.data.length < 1) {
            this.popupSwalWarning({
              title: 'Cảnh báo!',
              text: 'Không có dữ liệu cho report Số tồn - Số bán hằng ngày',
            });
            return;
          }

          this.$set(this, 'generalInformation', {
            name: 'chart2',
            children: data.data,
          });
        })
        .then(() => {
          this.$nextTick(() => {
            this.initializeD3HierarchyChart({
              domElementId: '#sales-capacity-inventory-general-information',
              data: Object.assign({}, this.generalInformation),
              receivedBarStep: 27,
              sumBy: 'netSales',
              eachAfters: [
                new EachAfterTranslation({
                  name: 'totalQuantity',
                  value: 'SL Bán',
                }),
                new EachAfterTranslation({
                  name: 'currentStockQuantity',
                  value: 'SL Tồn',
                }),
              ],
              endAlignmentFontSize: '0.8rem',
              additionalBarTextDisplayType: 'inline',
              enableChartAnimation: false,
              containerRef: this.$refs.barChartContainer,
              enableSortDesc: false,
            });
          });
        })
        .catch((error) => {
          if (error) {
            this.popupSwalError({
              title: 'Lỗi!',
              text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
            });
          }
        })
        .finally(() => {
          this.isLoading.generalInformation = false;
        });
    },
    fetchParentCategories() {
      return new Promise((resolve, reject) => {
        ApiService.get(cmdUrl.BIReportResource.categories.parents)
          .then(({ data }) => {
            this.categoryOptions = data.data;
            resolve();
          })
          .catch((error) => {
            this.popupSwalError({
              title: 'Lỗi!',
              text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
            });
            reject(error);
          });
      });
    },
    fetchEmployeeCategories() {
      ApiService.get(cmdUrl.BIReportResource.categories.employees)
        .then(({ data }) => {
          this.filteredEmployeeOptions = data.data.map((item) => {
            item.checked = false;
            return item;
          });
        })
        .catch((error) => {
          this.popupSwalError({
            title: 'Lỗi!',
            text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
          });
        });
    },
    loadCategoryOptions({ action, parentNode, callback }) {
      ApiService.get(
        cmdUrl.BIReportResource.categories.children.replace(
          '{parentId}',
          parentNode.id,
        ),
      )
        .then(({ data }) => {
          if (action === LOAD_CHILDREN_OPTIONS) {
            parentNode.children = data.data;
            callback();
          }
        })
        .catch((error) => {
          this.popupSwalError({
            title: 'Lỗi!',
            text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
          });
        });
    },
    getPayloadOnMount() {
      this.endDate = getCurrentDateByFormat({
        enableCustomCurrentDate: true,
        currentDate: '01/03/2023',
      });
    },
    storeKeywordChangeHandler(textInput) {
      this.storeKeyword = textInput;
    },

    getEmployeeResources() {
      ApiService.query(cmdUrl.BIReportResource.employees.resources)
        .then(({ data }) => {
          this.storeIds = data.data;

          this.filteredStoreOptions = data.data.map((store) => {
            store.checked = true;
            return store;
          });

          if (Array.isArray(this.storeIds) && this.storeIds.length) {
            this.getPayloadOnMount();
          }
        })
        .catch((error) => {
          this.popupSwalError({
            title: 'Lỗi!',
            text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
          });
        });
    },

    onInputChangeStore(textInput = '') {
      this.searchStore(textInput);
    },

    searchStore(textInput) {
      let options = cloneDeep(this.selectedStores);
      if (!textInput || !textInput.trim().length) {
        this.filteredStoreOptions = map(options, obj => {
          return assign(obj, find(this.filteredStoreOptions, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex(prop => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, obj => {
        return assign(obj, find(this.filteredStoreOptions, { id: obj.id }));
      });

      this.filteredStoreOptions = this.filterOptionsBy(options, textInput, 'name', 10);
    },

    filterOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter(item => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
  },
  watch: {
    multiple(newValue) {
      if (newValue) {
        this.value = this.value ? [this.value] : [];
      } else {
        this.value = this.value[0];
      }
    },
  },
  created() {
    this.fetchStoresByJobTitle();
  },
  mounted: async function() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Báo cáo ngành hàng', route: '/bi-report' },
      { title: 'Sức bán & Tồn kho' },
    ]);

    this.getPayloadOnMount();
    await this.fetchParentCategories();
    await this.fetchStoresByJobTitle();
    await this.fetchData();
  },
  computed: {
    formattedRevenue() {
      return formatPrice(this.revenuePerStore);
    },
    noMandatoryFields() {
      return !this.endDate || this.endDate.length < 1;
    },
    payloadMonth() {
      return getMonth(this.endDate) + 1;
    },
    getLoadingStatus() {
      const {
        salesCapacity,
        predictedDates,
        datesToClearStockRevenue,
        netSalesPerWeekRevenue,
        meanSalesCapacityOriginalPrice7Days,
        datesToClearStockOriginalPrice,
        generalInformation,
      } = this.isLoading;
      return (
        salesCapacity &&
        predictedDates &&
        datesToClearStockRevenue &&
        netSalesPerWeekRevenue &&
        meanSalesCapacityOriginalPrice7Days &&
        datesToClearStockOriginalPrice &&
        generalInformation
      );
    },
  },
};
</script>
