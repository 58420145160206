import * as d3 from 'd3';
import FunnelChart from '@/view/mixins/d3/modules/FunnelChart';

export const d3FunnelChartMixins = {
  methods: {
    initializeD3FunnelChart({
      data,
      domElementId,
      height = 600,
      width = 1200,
    }) {
      const svg = d3
        .select(domElementId)
        .html('')
        .append('svg')
        .attr('cursor', 'default')
        .attr('viewBox', [0, 0, width, height])
        .attr('transform', `scale(1) translate(0, 0)`);


      new FunnelChart(svg)
        .size([width, height])
        .options({ style: '2d', percentage: 'first' })
        .data(data)
        .render();
        // .onclick((d) => console.log(d))
        // .onhover((d) => console.log(d));
    },
  },
};
